<template>
  <div class="product-grid">
    <ProductTile 
      v-for="(product, index) in products" 
      :key="index" 
      :product="product" 
    />
  </div>
</template>

<script>
import ProductTile from './ProductTile.vue';
import productsData from '../assets/products_new.json';

export default {
  name: 'AppUrunler',
  components: {
    ProductTile,
  },
  data() {
    return {
      products: productsData.products,
    };
  },
};
</script>

<style scoped>
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}
</style>
