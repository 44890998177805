<template>
  <div class="header-container">
    <header class="header">
      <nav class="nav">
        <img src="@/assets/logo_big.png" alt="Logo" class="logo" @click="navigateTo('LandingPage')" />
        <a @click.prevent="navigateTo('AppUrunler')">Ürünler</a>
        <a @click.prevent="navigateTo('AppKurumsal')">Kurumsal</a> <!-- New button -->
        <a @click.prevent="navigateTo('AppIletisim')">İletişim</a>
        <a @click.prevent="navigateTo('AppKariyer')">Kariyer</a>
        
      </nav>
    </header>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'AppHeader',
  setup() {
    const router = useRouter();
    const navigateTo = (routeName) => {
      router.push({ name: routeName });
    };

    return { navigateTo };
  }
};
</script>

<style scoped>
.header-container {
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  height: 70px; /* Set a fixed height */
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px; /* Add margin between items */
}

.logo {
  height: 35px; /* Adjust as needed */
  cursor: pointer;
}

.nav a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
  margin: 0 10px;
}

.nav a:hover {
  cursor: pointer;
}
</style>
