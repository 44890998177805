<template>
    <footer class="app-footer">
      <div class="footer-section" v-for="(section, index) in sections" :key="index">
        <h3 @click="toggleSection(index)">
          {{ section.title }}
          <span class="dropdown-indicator">
            {{ isSectionOpen(index) ? '-' : '+' }}
          </span>
        </h3>
        <ul v-show="isSectionOpen(index)">
          <li v-for="(subtitle, idx) in section.subtitles" :key="idx">{{ subtitle }}</li>
        </ul>
        <div class="separator"></div>
      </div>
      <div class="footer-section logo-section">
        <img src="@/assets/logo.png" alt="Company Logo" />
      </div>
    </footer>
  </template>
  
  <script>
  import productsData from '@/assets/landing_tiles.json';
  
  export default {
    name: 'AppFooterMobile',
    data() {
      return {
        sections: [
          {
            title: 'Kurumsal',
            subtitles: ['Hakkımızda', 'Misyon & Vizyon', 'Çevre Politikamız'],
            path: '/kurumsal'
          },
          {
            title: 'Ürünler',
            subtitles: [],
            path: '/urunler'
          },
          {
            title: 'İletişim',
            subtitles: ['İletişim Bilgileri', 'Destek'],
            path: '/iletisim'
          },
          {
            title: 'Kariyer',
            subtitles: ['İş Başvurusu'],
            path: '/kariyer'
          },
          {
            title: 'Diğer',
            subtitles: ['Şartlar ve Koşullar', 'Gizlilik'],
            path: '/'
          }
        ],
        openSections: []
      };
    },
    created() {
      this.loadProductBrands();
    },
    methods: {
      loadProductBrands() {
        const brands = productsData.products.map(product => product.name);
        this.sections[1].subtitles = brands;
      },
      toggleSection(index) {
        if (this.openSections.includes(index)) {
          this.openSections = this.openSections.filter(i => i !== index);
        } else {
          this.openSections.push(index);
        }
      },
      isSectionOpen(index) {
        return this.openSections.includes(index);
      }
    }
  };
  </script>
  
  <style scoped>
  html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .app-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    color: #000;
    padding: 20px;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the element's total width */
  }
  
  .footer-section {
    width: 100%;
    padding: 0 10px;
    text-align: left;
    box-sizing: border-box; /* Ensure padding is included in the element's total width */
  }
  
  .logo-section img {
    width: 100%; /* Ensure the logo fits within the container */
    max-width: 200px;
    height: auto;
  }
  
  .footer-section h3 {
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-section li {
    margin: 3px 0;
  }
  
  .separator {
    height: 1px;
    background-color: #ccc;
    width: 100%;
    margin: 10px 0;
  }
  
  .dropdown-indicator {
    font-size: 20px;
  }
  
  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box; /* Ensure padding is included in the element's total width */
  }
  </style>
  