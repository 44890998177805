<template>
  <div>
    <UnderConstruction />
  </div>
</template>

<script>
import UnderConstruction from '@/components/UnderConstruction.vue';

export default {
  name: 'AppKariyer',
  components: {
    UnderConstruction
  }
};
</script>
