<template>
    <div class="brand-highlight">
      <div class="overlay"></div>
      <img src="@/assets/logo_o.png" alt="Company Logo" class="logo">
      <div class="text">
        OCN GÜBRE ÜRETİM PAZARLAMA SANAYİ 2021 yılında Samsun merkezli kurulmuş bir Gübre Firmasıdır.
        <br><br>
        Yerli ve Global bağlantılarıyla bölgesindeki tarımsal üretimi, Kaliteli ürün, Doğru İçerik ve alanında uzman kadrosu ile sürdürülebilir çözümlere ulaştırmayı misyon edinmiştir.
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BrandHighlight'
  };
  </script>
  
  <style scoped>
  .brand-highlight {
    background-color: white;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .overlay {
    position: absolute;
    background-color: white;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .logo {
    width: 30%;
    height: auto;
    filter: opacity(0.5); /* Decrease logo visibility */
    position: absolute;
    z-index: 2;
  }
  
  .text {
    position: absolute;
    z-index: 3;
    color: black;
    font-size: 1.2rem; /* Adjust font size as needed */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
    border-radius: 10px;
  }
  </style>
  