<template>
    <div class="header-container">
      <header class="header">
        <nav class="nav">
          <img src="@/assets/logo.png" alt="Logo" class="logo" @click="navigateTo('LandingPage')" />
          <div class="menu-icon" @click="toggleMenu">
            <font-awesome-icon :icon="['fas', 'bars']" />
          </div>
        </nav>
      </header>
      <div class="mobile-menu" v-if="isMenuOpen">
        <div class="menu-header">
          <div class="close-icon" @click="toggleMenu">
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
        </div>
        <div class="menu-content">
          <a @click.prevent="navigateTo('AppUrunler')">Ürünler</a>
          <a @click.prevent="navigateTo('AppIletisim')">İletişim</a>
          <a @click.prevent="navigateTo('AppKariyer')">Kariyer</a>
          <a @click.prevent="navigateTo('AppKurumsal')">Kurumsal</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';
  
  export default {
    name: 'AppHeaderMobile',
    setup() {
      const router = useRouter();
      const isMenuOpen = ref(false);
  
      const navigateTo = (routeName) => {
        router.push({ name: routeName });
        isMenuOpen.value = false; // Close the menu on navigation
      };
  
      const toggleMenu = () => {
        isMenuOpen.value = !isMenuOpen.value;
      };
  
      return { navigateTo, toggleMenu, isMenuOpen };
    }
  };
  </script>
  
  <style scoped>
  .header-container {
    position: relative;
    width: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    height: 70px;
    padding: 0 20px;
  }
  
  .nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  .logo {
    height: 60px; /* Increase logo height */
    cursor: pointer;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000; /* Ensure the menu is on top */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .menu-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px; /* Add padding to the menu header */
    padding-bottom: 0; /* Remove bottom padding to align with content */
  }
  
  .close-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .menu-content {
    margin-top: 20px; /* Add some space below the close icon */
    text-align: left; /* Ensure left alignment */
    padding: 20px; /* Add padding to match the header padding */
    padding-top: 0; /* Remove top padding to align with header */
  }
  
  .menu-content a {
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 24px;
    margin: 10px 0;
  }
  
  .menu-content a:hover {
    cursor: pointer;
  }
  </style>
  