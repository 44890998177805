<template>
    <div class="under-construction">
      <div class="section logo-section">
        <img src="@/assets/logo.png" alt="Logo" class="logo" />
      </div>
      <div class="section image-section">
        <img src="@/assets/under_construction.png" alt="Under Construction" class="under-construction-image" />
      </div>
      <div class="section text-section">
        <div class="text">
          <div>Tasarım ve Geliştirme</div>
          <div>www.erdemkaratas.com</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UnderConstruction',
  }
  </script>
  
  <style scoped>
  .under-construction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box; /* Ensures padding is included in the total width/height */
  }
  
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px; /* Adds spacing between sections */
  }
  
  .logo {
    width: 60%;
    max-width: 150px;
    height: auto;
  }
  
  .under-construction-image {
    width: 80%;
    max-width: 600px;
    height: auto;
  }
  
  .text {
    font-size: 1rem;
    color: #333;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .under-construction {
      flex-direction: row;
      padding: 40px;
    }
    .section {
      flex: 1;
      margin-bottom: 0; /* Removes spacing between sections for larger screens */
    }
    .logo {
      width: 80%;
    }
  }
  </style>
  