<template>
  <div>
    <BrandHighlight />
  </div>
</template>

<script>
import BrandHighlight from '@/components/BrandHighlight.vue';

export default {
  name: 'AppKurumsal',
  components: {
    BrandHighlight
  }
};
</script>
