<template>
  <footer class="app-footer">
    <div class="footer-section logo-section">
      <img src="@/assets/logo.png" alt="Company Logo" />
    </div>
    <div class="footer-section" v-for="(section, index) in sections" :key="index">
      <h3 @click="navigateTo(section.path)">{{ section.title }}</h3>
      <ul>
        <li v-for="(subtitle, idx) in section.subtitles" :key="idx">{{ subtitle }}</li>
      </ul>
    </div>
  </footer>
</template>

<script>
import productsData from '@/assets/landing_tiles.json';

export default {
  name: 'AppFooter',
  data() {
    return {
      sections: [
        {
          title: 'Kurumsal',
          subtitles: ['Hakkımızda', 'Misyon & Vizyon', 'Çevre Politikamız'],
          path: '/kurumsal'
        },
        {
          title: 'Ürünler',
          subtitles: [],
          path: '/urunler'
        },
        {
          title: 'İletişim',
          subtitles: ['İletişim Bilgileri','Destek'],
          path: '/iletisim'
        },
        {
          title: 'Kariyer',
          subtitles: ['İş Başvurusu'],
          path: '/kariyer'
        },
        {
          title: 'Diğer',
          subtitles: ['Şartlar ve Koşullar', 'Gizlilik'],
          path: '/'
        }
      ]
    };
  },
  created() {
    this.loadProductBrands();
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    loadProductBrands() {
      const brands = productsData.products.map(product => product.name);
      this.sections[1].subtitles = brands;
    }
  }
};
</script>

<style scoped>
.app-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  color: #000;
  padding: 20px;
  width: 100%;
}

.footer-section {
  flex: 1;
  padding: 0 10px;
  text-align: left;
}

.logo-section img {
  width: 200px;
  height: auto;
}

.footer-section h3 {
  cursor: pointer;
  margin-bottom: 5px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section li {
  margin: 3px 0;
}
</style>
