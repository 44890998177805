import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router).mount('#app');

// Initialize Google Maps asynchronously
window.initMap = function() {
  if (app._container.__vue__) {
    const appIletisimInstance = app._container.__vue__.$children.find(
      child => child.$options.name === 'AppIletisim'
    );
    if (appIletisimInstance && typeof appIletisimInstance.initMap === 'function') {
      appIletisimInstance.initMap();
    }
  }
};
