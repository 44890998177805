<template>
  <div class="wide-highlight">
    <div class="top-section">
      <div class="text-container">
        <div class="weak-text">{{ weakText }}</div>
      </div>
    </div>
    <div class="middle-section">
      <div class="image-container">
        <img :src="backgroundImage" alt="Highlight Image" class="highlight-image" />
      </div>
    </div>
    <div class="bottom-section">
      <button class="info-button" @click="openProjectUrl">Daha Fazla Bilgi</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WideHighlight',
  props: {
    backgroundImage: {
      type: String,
      required: true,
    },
    weakText: {
      type: String,
      required: true,
    },
    projectUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    openProjectUrl() {
      window.open(this.projectUrl, '_blank');
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wide-highlight {
  width: 100%;
  max-width: 100vw;
  height: 480px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc; /* Optional border for better visual separation */
  overflow: hidden;
}

.top-section {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px; /* Add padding for the text */
}

.middle-section {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-section {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  text-align: center;
  max-width: 800px; /* Limit the width of the text */
}

.weak-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5vh;
  color: #000;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.highlight-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.info-button {
  background-color: #000;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5vh;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.info-button:hover {
  background-color: #444;
}

/* Mobile styles */
@media (max-width: 768px) {
  .wide-highlight {
    height: 300px;
  }

  .top-section {
    height: 25vw;
  }

  .middle-section {
    height: 50vw;
  }

  .bottom-section {
    height: 25vw;
  }

  .weak-text {
    font-size: 2.5vw;
  }

  .info-button {
    font-size: 2.5vw;
    padding: 3vw 6vw;
  }
}
</style>
