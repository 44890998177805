import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/components/LandingPage.vue';
import AppUrunler from '@/components/AppUrunler.vue';
import AppIletisim from '@/components/AppIletisim.vue';
import AppKariyer from '@/components/AppKariyer.vue';
import AppKurumsal from '@/components/AppKurumsal.vue'; // Import the new component

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/urunler',
    name: 'AppUrunler',
    component: AppUrunler
  },
  {
    path: '/iletisim',
    name: 'AppIletisim',
    component: AppIletisim
  },
  {
    path: '/kariyer',
    name: 'AppKariyer',
    component: AppKariyer
  },
  {
    path: '/kurumsal',
    name: 'AppKurumsal', // Add the new route
    component: AppKurumsal
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
