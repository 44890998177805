<template>
  <div id="app">
    <WideHighlightContainer />
    <ProductTilesContainer />
  </div>
</template>

<script>
import ProductTilesContainer from './ProductTilesContainer.vue';
import WideHighlightContainer from './WideHighlightContainer.vue';

export default {
  name: 'LandingPage',
  components: {
    WideHighlightContainer,
    ProductTilesContainer
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
