<template>
  <div class="app-iletisim">
    <div class="right-section">
      <img :src="contact.logo_url" alt="Company Logo" class="logo">
      <p><strong>Adres:</strong> {{ contact.Adres }}</p>
      <p><strong>Telefon:</strong> {{ contact.Telefon }}</p>
      <p><strong>E-Mail:</strong> {{ contact["E-Mail"] }}</p>
    </div>
    <div class="left-section">
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import contactInfo from '@/assets/contact_info.json';

export default {
  name: 'AppIletisim',
  data() {
    return {
      contact: {}
    };
  },
  mounted() {
    this.contact = contactInfo.contact[0];
    if (typeof google !== 'undefined') {
      this.initMap();
    } else {
      window.initMap = this.initMap;
    }
  },
  methods: {
    initMap() {
      const location = { lat: 41.24720658562334, lng: 36.40790244461153 }; // Coordinates for the address

      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 20,
        center: location
      });

      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: location,
        map: map
      });
    }
  }
};
</script>

<style scoped>
.app-iletisim {
  display: flex;
  height: 500px; /* Fixed height for the entire component */
}

.left-section, .right-section {
  flex: 1;
  padding: 10px;
}

.left-section {
  background-color: #f0f0f0; /* Light grey background for left section */
  position: relative;
}

#map {
  width: 100%;
  height: 100%;
}

.right-section {
  background-color: #ffffff; /* White background for right section */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  overflow-y: auto; /* Add scroll if content overflows */
  padding: 20px; /* Add some padding for better presentation */
}

.logo {
  max-width: 20%;
  height: auto;
  margin-bottom: 20px; /* Space between logo and text */
}

/* Mobile view */
@media (max-width: 768px) {
  .app-iletisim {
    flex-direction: column;
    height: 1000px;
  }

  .left-section, .right-section {
    height: 50%; /* Each section takes 50% of the height */
  }
}
</style>
