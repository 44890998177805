<template>
  <div id="app">
    <component :is="currentHeaderComponent" />
    <div class="content">
      <router-view />
    </div>
    <component :is="currentFooterComponent" />
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import AppHeader from './components/AppHeader.vue';
import AppHeaderMobile from './components/AppHeaderMobile.vue';
import AppFooter from './components/AppFooter.vue';
import AppFooterMobile from './components/AppFooterMobile.vue';

export default {
  name: 'App',
  components: {
    AppFooter,
    AppFooterMobile
  },
  setup() {
    const isMobile = ref(window.innerWidth <= 768);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      window.addEventListener('resize', updateIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    const currentHeaderComponent = computed(() => {
      return isMobile.value ? AppHeaderMobile : AppHeader;
    });

    const currentFooterComponent = computed(() => {
      return isMobile.value ? AppFooterMobile : AppFooter;
    });

    return {
      currentHeaderComponent,
      currentFooterComponent
    };
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh; /* Ensures the height is at least the full viewport height */
  display: flex;
  flex-direction: column;
}

#app > * {
  flex-shrink: 0;
}

.content {
  flex-grow: 1; /* This ensures the content area takes up the remaining space */
  border-bottom: 1px solid #ccc; /* Add a slight border between content and footer */
}
</style>
